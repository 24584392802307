import { useState } from 'react'

import { Box, Stack } from '@mui/material'

import { Checkbox, Typography } from '@guidde/design-system'

import { useServerUserPreferences } from 'hooks'

export const AudioNoteSettings = () => {
    const { serverUserPreferences, updateAudioNoteSettings } = useServerUserPreferences()

    if (!serverUserPreferences) return null

    const { audioNote } = serverUserPreferences

    const [applyToSubtitles, setAppleToSubtitles] = useState(Boolean(audioNote?.applyToSubtitles))
    const [applyToDescription, setApplyToDescription] = useState(
        Boolean(audioNote?.applyToDescription)
    )

    return (
        <Box mb={1}>
            <Stack height={25} spacing={1} direction="row" alignItems="center">
                <Checkbox
                    indeterminate={false}
                    checked={applyToSubtitles}
                    onChange={() => {
                        setAppleToSubtitles(prevState => !prevState)
                        updateAudioNoteSettings({ applyToSubtitles: !applyToSubtitles })
                    }}
                />
                <Typography variant="text" size="sm">
                    Apply to Captions
                </Typography>
            </Stack>
            <Stack height={25} spacing={1} direction="row" alignItems="center">
                <Checkbox
                    indeterminate={false}
                    checked={applyToDescription}
                    onChange={() => {
                        setApplyToDescription(prevState => !prevState)
                        updateAudioNoteSettings({ applyToDescription: !applyToDescription })
                    }}
                />
                <Typography variant="text" size="sm">
                    Apply to Document description
                </Typography>
            </Stack>
        </Box>
    )
}
