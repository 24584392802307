import React from 'react'
import { Box, Skeleton } from '@mui/material'

interface SmartSkeletonProps {
    html: string
    lineHeight?: number
    spacing?: number
}

export const SmartSkeleton: React.FC<SmartSkeletonProps> = ({
    html,
    lineHeight = 20,
    spacing = 8
}) => {
    const lines = html.split(/<[^/][^>]*>/g).filter(line => line.trim().length > 0)

    return (
        <>
            {lines.map((line, index) => {
                const width = Math.min(100, Math.max(30, line.length * 2))
                return (
                    <Box key={index} mb={index < lines.length - 1 ? spacing / 8 : 0}>
                        <Skeleton variant="text" width={`${width}%`} height={lineHeight} />
                    </Box>
                )
            })}
        </>
    )
}
