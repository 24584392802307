import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import FontFamily from '@tiptap/extension-font-family'
import {
    HeadingDropdown,
    FontFamilyDropdown,
    TextAlignmentPopoverGroup,
    ListPopoverGroup,
    TextStylePopoverGroup,
    SetLink,
    LineHeightDropdown,
    TextDirectionPopoverGroup,
    TextColorPopoverGroup,
    TextBackgroundColorPopoverGroup
} from './Actions'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import TextAlign from '@tiptap/extension-text-align'
import { LineHeight, TextDirection } from './custom/extensions'
import Highlight from '@tiptap/extension-highlight'

export const availableExtensions = [
    StarterKit.configure({
        paragraph: {
            HTMLAttributes: {
                style: 'font-size: 14px'
            }
        }
    }),
    Image,
    Underline,
    Link.configure({
        defaultProtocol: 'https'
    }),
    FontFamily,
    TextStyle,
    Color,
    TextAlign.configure({
        types: ['heading', 'paragraph']
    }),
    LineHeight,
    TextDirection,
    Highlight.configure({ multicolor: true })
]

export const componentMap = {
    withFontFamily: FontFamilyDropdown,
    withHeading: HeadingDropdown,
    withTextStyle: TextStylePopoverGroup,
    withTextAlignment: TextAlignmentPopoverGroup,
    withListStyle: ListPopoverGroup,
    withLineHeight: LineHeightDropdown,
    withLink: SetLink,
    withTextDirection: TextDirectionPopoverGroup,
    withTextColor: TextColorPopoverGroup,
    withTextBackgroundColor: TextBackgroundColorPopoverGroup
}

export const defaultGuiddeRichtTextEditorConfiguration: GuiddeRichTextEditorConfiguration = {
    withLink: true,
    withHeading: true,
    withFontFamily: true,
    withTextStyle: true,
    withLineHeight: true,
    withTextAlignment: true,
    withTextDirection: true,
    withListStyle: true,
    withTextColor: true,
    withTextBackgroundColor: true
}

export type GuiddeRichTextEditorConfiguration = {
    withHeading?: boolean
    withFontFamily?: boolean
    withTextStyle?: boolean
    withTextAlignment?: boolean
    withTextDirection?: boolean
    withLineHeight?: boolean
    withListStyle?: boolean
    withLink?: boolean
    withTextColor?: boolean
    withTextBackgroundColor?: boolean
}
