import { Extension, type CommandProps } from '@tiptap/core'

interface LineHeightOptions {
    types: string[]
    defaultLineHeight: string
    lineHeights: { label: string; value: string }[]
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        lineHeight: {
            /**
             * Set the line height
             */
            setLineHeight: (lineHeight: string) => ReturnType
            /**
             * Unset the line height
             */
            unsetLineHeight: () => ReturnType
        }
    }
}

export const lineHeights = [
    { label: '1.0', value: '1.0' },
    { label: '1.5', value: '1.5' },
    { label: '2.0', value: '2.0' }
]

export const defaultLineHeight = '1.5'

export const LineHeight = Extension.create<LineHeightOptions>({
    name: 'lineHeight',

    addOptions() {
        return {
            types: ['textStyle'],
            defaultLineHeight,
            lineHeights
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: this.options.defaultLineHeight,
                        parseHTML: (element: HTMLElement) =>
                            element.style.lineHeight || this.options.defaultLineHeight,
                        renderHTML: (attributes: Record<string, any>) => {
                            if (!attributes.lineHeight) {
                                return {}
                            }
                            return {
                                style: `line-height: ${attributes.lineHeight}`
                            }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setLineHeight:
                (lineHeight: string) =>
                ({ chain }: CommandProps) => {
                    return chain()
                        .setMark('textStyle', {
                            lineHeight
                        })
                        .run()
                },
            unsetLineHeight:
                () =>
                ({ chain }: CommandProps) => {
                    return chain()
                        .setMark('textStyle', {
                            textStyle: null
                        })
                        .removeEmptyTextStyle()
                        .run()
                }
        }
    }
})
