import { arrayMoveImmutable } from 'array-move'

import { getMinLayerIndex } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/ContextMenu'

import * as types from 'ducks/types'
import { type ActionType } from 'ducks/common'

import {
    type AudioCircleType,
    type CircleShapeType,
    type CircleWithTextShapeType,
    type QuickGuiddeLayerType,
    type RectangleShapeType,
    type StepType,
    type TextShapeType,
    type VideoShapeType,
    type SpeechToTextType,
    Shape,
    type SubtitlesType,
    type SubtitleType
} from 'app/types'
import { uuid } from 'modules'

export const generateNumberedSteps = (steps: Array<StepType>, layer: CircleWithTextShapeType) => {
    const { text, circle } = layer

    const stepsBeforeIndex = steps
        .slice(0, 2) // we check only the steps before the regular kind:step
        .filter(step => ['intro', 'cover'].includes(step.kind)).length
    const indexDelta = 1 - stepsBeforeIndex // to make sure the first step is 01

    const adjustStepNumberLayer = (step: StepType, index: number) => {
        const stepIndex = (index + indexDelta).toString().padStart(2, '0')

        const textLayer = step.layers.find(layer => layer.type === Shape.Text) as
            | TextShapeType
            | undefined

        const isComplexBg = step.layers.some(layer => layer.type == Shape.BrowserBar)
        const scale = isComplexBg ? 0.8 : 1

        // Required available space for each side
        const { innerWidth, innerHeight } = step.windowDimensions

        const padding = 20
        const requiredSpace = padding * 2 + circle.radius * 2 * scale

        const choosePosition = () => {
            const defaultPosition = { x: padding, y: padding }
            if (!textLayer) return defaultPosition

            const positionOptions = [
                {
                    direction: 'Left',
                    x: textLayer.x - requiredSpace + padding,
                    y: textLayer.y,
                    availableSpace: textLayer.x
                },
                {
                    direction: 'Top',
                    x: textLayer.x,
                    y: textLayer.y - requiredSpace + padding,
                    availableSpace: textLayer.y
                },
                {
                    direction: 'Bottom',
                    x: textLayer.x,
                    y: textLayer.y + textLayer.height * textLayer.scaleY + padding,
                    availableSpace: innerHeight - textLayer.height * textLayer.scaleY
                },
                {
                    direction: 'Right',
                    x: textLayer.x + (textLayer.width || 0) * textLayer.scaleX + padding,
                    y: textLayer.y,
                    availableSpace:
                        innerWidth - (textLayer.x + (textLayer.width || 0) * textLayer.scaleX)
                }
            ]

            const validPosition = positionOptions.find(
                option => option.availableSpace >= requiredSpace
            )

            const { x, y } = validPosition || defaultPosition
            return { x, y }
        }

        const isMoreThanTwoDigits = stepIndex.length > 2
        if (isMoreThanTwoDigits) text.fontSize = 70

        return {
            ...layer,
            scaleX: scale,
            scaleY: scale,
            text: { ...text, title: stepIndex },
            ...choosePosition()
        }
    }

    return steps.map((step, index) => {
        if (step.kind === 'cover' || step.kind === 'end') return step

        const videoStep = step.layers.find(layer => layer.type === Shape.Video)
        if (videoStep) return step

        return {
            ...step,
            layers: [...step.layers, adjustStepNumberLayer(step, index)]
        }
    })
}

type StateType = {
    isEditorBlockingShortcuts: boolean
    activeStep: number
    imageStepArea: { visible: boolean; placement: 'before' | 'after' }
    selectedLayersIds: Array<string>
    selectedSteps: Array<string>
    copiedLayers: Array<QuickGuiddeLayerType>
    steps: Array<StepType>
}

const initialState: StateType = {
    isEditorBlockingShortcuts: false,
    imageStepArea: { visible: false, placement: 'after' },
    activeStep: 0,
    selectedLayersIds: [],
    selectedSteps: [],
    copiedLayers: [],
    steps: []
}

export const qgEditorReducer = (state = initialState, action: ActionType): StateType => {
    const { activeStep, imageStepArea, steps, selectedLayersIds, copiedLayers, selectedSteps } =
        state

    switch (action.type) {
        case types.SET_STEP_TRANSITION: {
            const { transition, applyToAll } = action.payload || {}

            const newSteps = steps.map((step, stepIndex) => {
                if (!applyToAll && stepIndex !== activeStep) return step

                if (!transition) {
                    const { transition, ...rest } = step
                    return rest
                }

                return { ...step, transition }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_STEP_CTA: {
            const newSteps: Array<StepType> = structuredClone(steps)
            if (action.payload) {
                newSteps[activeStep].cta = action.payload
            } else {
                const { cta, ...rest } = newSteps[activeStep]
                newSteps[activeStep] = rest
            }

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_ARROW_DIRECTION: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (layer.id !== selectedLayersIds[0]) return layer

                        return {
                            ...layer,
                            dataKey: action.payload
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_STEP_LAYER: {
            const { layerId, layer } = action.payload

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: step.layers.map(oldLayer => {
                        if (oldLayer.id === layerId) {
                            return layer
                        } else {
                            return oldLayer
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_STEPS: {
            const { steps, partialUpdate = false } = action.payload

            return {
                ...(partialUpdate ? state : initialState),
                steps,
                activeStep: Math.max(0, Math.min(activeStep, steps.length - 1)),
                selectedLayersIds,
                selectedSteps,
                imageStepArea
            }
        }

        case types.PASTE_COPIED_STEPS: {
            const { steps, activeStep } = action.payload

            return {
                ...initialState,
                steps,
                activeStep,
                selectedLayersIds: [],
                selectedSteps: [],
                imageStepArea
            }
        }

        case types.SET_QUICK_GUIDDE_DURATION: {
            const { duration, applyToAll } = action.payload

            const durationError = "Caption block can't exceed the step's duration"

            const validateSubtitles = (subtitles: SubtitlesType) => {
                return subtitles.map(subtitle => {
                    if (subtitle.end > duration) return { ...subtitle, error: durationError }
                    // Remove error if it was set before
                    if (
                        subtitle?.error === durationError || // @TODO For backward compatibility, we will remove it on the next release
                        subtitle?.timeError === durationError
                    ) {
                        const { error, timeError, ...rest } = subtitle
                        return rest
                    }
                    return subtitle
                })
            }

            const newSteps = steps.map((step, stepIndex) => {
                const validatedSubtitles = step.subtitles
                    ? { subtitles: validateSubtitles(step.subtitles) }
                    : {}

                // Change Duration of current step even if it has audio
                if (stepIndex === activeStep || selectedSteps.includes(step.id))
                    return {
                        ...step,
                        duration,
                        ...validatedSubtitles
                    }

                // Don't change duration of other steps that have audio or if applyToAll is not enabled
                if (!applyToAll || (step.audioNote && step.audioNote.type !== 'defaultSubtitles'))
                    return step

                // Change all other steps which has no audio and applyToAll is enabled
                return { ...step, duration, ...validatedSubtitles }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_IMAGE_STEP_AREA: {
            return {
                ...state,
                imageStepArea: action.payload
            }
        }

        case types.SET_ACTIVE_STEP: {
            const { stepIndex, isForced } = action.payload

            const protectedIndex = Math.max(0, Math.min(stepIndex, steps.length - 1))
            const newActiveStep = isForced ? stepIndex : protectedIndex

            return {
                ...state,
                activeStep: newActiveStep,
                selectedLayersIds: [],
                selectedSteps: []
            }
        }

        case types.TOGGLE_MULTIPLE_SELECTED_STEPS: {
            const { stepId, stepIndex } = action.payload

            const isSelected = selectedSteps.includes(stepId)

            const activeStepId = steps[activeStep].id
            const isActiveStepSelected = activeStepId === stepId
            const selectedStepIdx = selectedSteps.findIndex(stepId => stepId === stepId)

            const newActiveStepId =
                selectedSteps[selectedStepIdx - 1] || selectedSteps[selectedStepIdx + 1]

            const newActiveStepIdx = isActiveStepSelected
                ? steps.findIndex(step => step.id === newActiveStepId)
                : activeStep

            if (isSelected) {
                return {
                    ...state,
                    selectedSteps: selectedSteps.filter(i => i !== stepId),
                    activeStep: newActiveStepIdx >= 0 ? newActiveStepIdx : activeStep,
                    selectedLayersIds: []
                }
            }
            const stepsSchemaIdxId: { [key: number]: string } = {}
            const stepsSchemaIdIdx: { [key: string]: number } = {}

            steps.forEach((step, index) => {
                stepsSchemaIdxId[index] = step.id
                stepsSchemaIdIdx[step.id] = index
            })

            const selectedStepsIndexes = selectedSteps.map(stepId => stepsSchemaIdIdx[stepId])

            const indexesRange = [stepIndex, activeStep, ...selectedStepsIndexes]

            const start = Math.min(...indexesRange)
            const end = Math.max(...indexesRange)

            const newSelectedSteps: Array<string> = []

            for (let i = start; i <= end; i++) {
                if (stepsSchemaIdxId[i]) newSelectedSteps.push(stepsSchemaIdxId[i])
            }

            return {
                ...state,
                selectedSteps: newSelectedSteps,
                activeStep: stepIndex,
                selectedLayersIds: []
            }
        }

        case types.SET_LAYER_POSITION: {
            const { x, y, layerId } = action.payload

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (layer.id !== layerId) return layer

                        return {
                            ...layer,
                            x,
                            y
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }
        case types.SET_LAYER_FILL: {
            const { fill, strokeColor, circle, idx } = action.payload
            const newSteps = structuredClone(steps)

            let currentLayer = newSteps[activeStep].layers[idx]

            if (circle && 'circle' in currentLayer) {
                currentLayer.circle = {
                    ...currentLayer.circle,
                    ...circle
                }
            }

            if (strokeColor && 'strokeColor' in currentLayer) {
                currentLayer.strokeColor = strokeColor
            }

            if (fill && 'fill' in currentLayer) {
                currentLayer.fill = fill
            }

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_LAYER_TRANSFORM: {
            const { layerId, x, y, width, height, scaleX, scaleY, rotation, radius, fontSize } =
                action.payload

            const newSteps = structuredClone(steps)
            const currentLayer = newSteps[activeStep].layers.find(layer => layer.id === layerId)

            if (!currentLayer) return state

            currentLayer.x = x
            currentLayer.y = y
            currentLayer.rotation = rotation

            if (width && 'width' in currentLayer) {
                currentLayer.width = width
            }
            if (height && 'height' in currentLayer) {
                currentLayer.height = height
            }
            if (scaleX && scaleY) {
                currentLayer.scaleX = scaleX
                currentLayer.scaleY = scaleY
            }
            if (radius && 'radius' in currentLayer) {
                currentLayer.radius = radius
            }
            if (fontSize && 'fontSize' in currentLayer) {
                currentLayer.fontSize = fontSize
            }

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_LAYER_TEXT: {
            const { layerId, title, height } = action.payload

            const layerIndex = steps[activeStep].layers.findIndex(layer => {
                return layer.id === layerId
            })

            if (layerIndex === -1) return state

            const newSteps = structuredClone(steps)
            const textLayer = newSteps[activeStep].layers[layerIndex] as TextShapeType
            textLayer.title = title

            if (title && textLayer.isTitle) {
                newSteps[activeStep].title = title
            }

            if (height) {
                textLayer.height = height
            }

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_LAYER_ORDER: {
            const { layers } = steps[activeStep]
            const { layerId, order } = action.payload

            const getNewIndex = () => {
                const maxIndex = layers.length - 1
                const minIndex = getMinLayerIndex(layers)

                switch (order) {
                    case 'front':
                        return maxIndex
                    case 'forward':
                        return Math.min(currentIndex + 1, maxIndex)
                    case 'backward':
                        return Math.max(currentIndex - 1, minIndex)
                    case 'back':
                        return minIndex
                }
            }

            const currentIndex = layers.findIndex(layer => layer.id === layerId)
            const newIndex = getNewIndex()

            if (currentIndex === -1 || currentIndex === newIndex) return state

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: arrayMoveImmutable(step.layers, currentIndex, newIndex)
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.TOGGLE_SPOTLIGHT: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const currentIndex = step.layers.findIndex(
                    layer => layer.id === selectedLayersIds[0]
                )
                const currentLayer = step.layers[currentIndex] as
                    | RectangleShapeType
                    | CircleShapeType

                const minIndex = getMinLayerIndex(step.layers)

                return {
                    ...step,
                    layers: (currentLayer.isSpotlight
                        ? step.layers // if layer is already spotlight, remove spotlight
                        : arrayMoveImmutable(step.layers, currentIndex, minIndex)
                    ) // if not a spotlight, move it to the top and only then add spotlight
                        .map(layer => {
                            if (layer.id !== selectedLayersIds[0]) return layer
                            if (layer.type === Shape.Rectangle || layer.type === Shape.Circle) {
                                return { ...layer, isSpotlight: !layer?.isSpotlight }
                            }
                            return layer
                        })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.ADD_STEP_NUMBERING: {
            return {
                ...state,
                steps: generateNumberedSteps(steps, action.payload)
            }
        }

        case types.REMOVE_STEP_NUMBERING: {
            const newSteps = steps.map(step => {
                if (step.kind === 'cover' || step.kind === 'end') return step
                return {
                    ...step,
                    layers: step.layers.filter(step => step.type !== Shape.CircleWithText)
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_STEP_NUMBERING_TEXT: {
            const { layerId, title } = action.payload

            const newSteps = structuredClone(steps)
            const currentLayer = newSteps[activeStep].layers.find(layer => layer.id === layerId)
            if (!currentLayer) return state

            if ('text' in currentLayer) currentLayer.text.title = title

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_BACKGROUND_LAYER: {
            const newSteps = steps.map((step, stepIndex) => {
                const { newLayer, applyToAll } = action.payload

                // Be careful with this condition, it's a bit tricky.
                // If we apply background to all steps - we need to change any step which is not cover or end
                if (stepIndex !== activeStep && (!applyToAll || step.kind !== 'step')) return step

                // It means that we have a combination of bg image and bg rectangle
                const isComplexBg = step.layers.filter(layer => 'isBackground' in layer).length > 1

                return {
                    ...step,
                    layers: step.layers.flatMap(layer => {
                        // If we update bg image layer, we need to remove blur layer
                        if (newLayer.type === Shape.Image && layer.type === Shape.Blur) return []

                        if ('isBackground' in layer && layer.isBackground) {
                            if (layer.type === Shape.Video) return layer // Do not update video layer

                            if (isComplexBg) {
                                // Update only relevant bg layer (image or rectangle)
                                if (layer.type === newLayer.type) return newLayer

                                return layer
                            }
                            // Update the only bg layer, regardless of type (rectangle or image)
                            else return newLayer
                        }

                        return layer
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.ADD_NEW_LAYER: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const bgLayers = step.layers.filter(layer => {
                    return (
                        (layer.type === Shape.Image || layer.type === Shape.Rectangle) &&
                        layer.isBackground
                    )
                }).length

                const overlayLayer = step.layers.find(layer => layer.type === Shape.Overlay) ? 1 : 0

                const getLayerIndex = () => {
                    switch (action.payload.type) {
                        case Shape.Overlay:
                            return bgLayers // add overlay after layers with isBackground
                        case Shape.Blur:
                            return bgLayers + overlayLayer // add blur after layers with isBackground and overlay
                        case Shape.Rectangle:
                        case Shape.Circle:
                            if (action.payload.isSpotlight) return getMinLayerIndex(step.layers) // add spotlight after bg, browser and overlay
                            return step.layers.length
                        default:
                            return step.layers.length // add new layer to the end
                    }
                }

                return {
                    ...step,
                    layers: [
                        // part of the array before the specified index
                        ...step.layers.slice(0, getLayerIndex()),
                        // inserted item
                        action.payload,
                        // part of the array after the specified index
                        ...step.layers.slice(getLayerIndex())
                    ]
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_COMPANY_LOGO: {
            if (selectedLayersIds.length !== 1) return state

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step
                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (!selectedLayersIds.includes(layer.id)) return layer

                        return { ...layer, ...action.payload }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_FONT_STYLES: {
            if (selectedLayersIds.length !== 1) return state

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const { align, fontSize, fontStyle, textDecoration, fontFamily } = action.payload

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (!selectedLayersIds.includes(layer.id)) return layer

                        return {
                            ...layer,
                            ...(fontFamily && { fontFamily }),
                            ...(align !== undefined && { align }),
                            ...(fontSize !== undefined && { fontSize }),
                            ...(fontStyle !== undefined && { fontStyle }),
                            ...(textDecoration !== undefined && { textDecoration })
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_TEXT_SHAPE_SIZE: {
            const { id, height, width } = action.payload
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (layer.id !== id) return layer

                        return {
                            ...layer,
                            height,
                            width
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_TEXT_COLORS: {
            if (selectedLayersIds.length !== 1) return state

            const { color, backgroundColor } = action.payload

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (!selectedLayersIds.includes(layer.id)) return layer

                        return {
                            ...layer,
                            ...(color ? { color } : {}),
                            ...(backgroundColor ? { backgroundColor } : {})
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.MOVE_SELECTED_LAYER: {
            if (!selectedLayersIds.length) return state

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const newLayers = step.layers.map(layer => {
                    if (!selectedLayersIds.includes(layer.id)) return layer

                    const direction = action.payload.direction
                    if (direction === 'TOP') layer.y -= 10
                    if (direction === 'RIGHT') layer.x += 10
                    if (direction === 'BOTTOM') layer.y += 10
                    if (direction === 'LEFT') layer.x -= 10

                    return {
                        ...layer
                    }
                })

                return {
                    ...step,
                    layers: newLayers
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }
        case types.RESET_COPIED_LAYER: {
            return {
                ...state,
                copiedLayers: []
            }
        }

        case types.COPY_SELECTED_LAYER: {
            if (!selectedLayersIds.length) return state

            const layers = steps[activeStep].layers.filter(layer =>
                selectedLayersIds.includes(layer.id)
            )

            return {
                ...state,
                copiedLayers: layers
            }
        }

        case types.PASTE_SELECTED_LAYER: {
            if (!copiedLayers.length) return state

            const isVideoStep = steps[activeStep].layers.find(layer => layer.type === Shape.Video)
            if (isVideoStep) return state // We do not allow pasting any layer to the step with video

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step
                const isOriginalStep = step.layers.find(layer =>
                    copiedLayers.find(copiedLayer => copiedLayer.id === layer.id)
                )

                return {
                    ...step,
                    layers: [
                        ...step.layers,
                        ...copiedLayers.map(copiedLayer => {
                            return {
                                ...copiedLayer,
                                ...('isTitle' in copiedLayer ? { isTitle: false } : {}),
                                id: uuid(),
                                x: copiedLayer.x + (isOriginalStep ? 100 : 0),
                                y: copiedLayer.y + (isOriginalStep ? 100 : 0)
                            }
                        })
                    ]
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.DUPLICATE_SELECTED_LAYER: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const selectedLayers = step.layers.filter(layer =>
                    selectedLayersIds.includes(layer.id)
                )

                const lastIndex = step.layers.findLastIndex(layer =>
                    selectedLayersIds.includes(layer.id)
                )

                return {
                    ...step,
                    layers: [
                        ...step.layers.slice(0, lastIndex + 1),
                        // Paste selected layers with offset after the last selected layer
                        ...selectedLayers.map(layer => {
                            return {
                                ...layer,
                                x: layer.x + 100,
                                y: layer.y + 100,
                                id: uuid(),
                                ...('isTitle' in layer ? { isTitle: false } : {})
                            }
                        }),
                        ...step.layers.slice(lastIndex + 1)
                    ]
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.REMOVE_SELECTED_LAYER: {
            if (!selectedLayersIds.length) return state

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step
                return {
                    ...step,
                    layers: step.layers.filter(layer => !selectedLayersIds.includes(layer.id))
                }
            })

            return {
                ...state,
                steps: newSteps,
                selectedLayersIds: []
            }
        }

        case types.OVERWRITE_STEP: {
            const { newStep, stepId } = action.payload

            const newSteps = steps.map((step, stepIndex) => {
                const isWrongStepId = stepId && step.id !== stepId
                const isWrongStepIndex = !stepId && stepIndex !== activeStep

                if (isWrongStepId || isWrongStepIndex) return step
                return newStep
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_VIDEO_PREVIEW:
            return {
                ...state,
                steps: steps.map((step, stepIndex) => {
                    if (stepIndex !== activeStep) return step

                    return {
                        ...step,
                        layers: step.layers.map(layer => {
                            if (layer.type !== Shape.Video) return layer

                            return {
                                ...layer,
                                sourceVideoThumbnailPreview: action.payload
                            }
                        })
                    }
                })
            }

        case types.ADD_NEW_STEP: {
            const { step, kind } = action.payload

            switch (kind) {
                case 'intro':
                    return {
                        ...state,
                        activeStep: 0,
                        selectedLayersIds: [],
                        steps: [step, ...steps]
                    }
                case 'cover':
                    const coverIndex = steps[0].kind === 'intro' ? 1 : 0

                    return {
                        ...state,
                        steps: [...steps.slice(0, coverIndex), step, ...steps.slice(coverIndex)],
                        activeStep: coverIndex,
                        selectedLayersIds: []
                    }
                case 'step':
                    let initialIndex = activeStep

                    const activeStepKind = steps[activeStep].kind
                    // If current step is intro, but we have 'cover' - we need to add step after cover
                    if (activeStepKind === 'intro' && steps[activeStep + 1]?.kind === 'cover')
                        initialIndex += 1
                    // If current step is outro, but we have 'end' - we need to add step before end
                    if (activeStepKind === 'outro' && steps[activeStep - 1]?.kind === 'end')
                        initialIndex -= 1

                    const newIndex = initialIndex + (action.payload.position === 'after' ? 1 : 0)

                    const newSteps = [
                        ...steps.slice(0, newIndex),
                        step,
                        ...steps.slice(newIndex).map((step, stepIndex) => {
                            return {
                                ...step,
                                // update step numbering if the one exists
                                layers: step.layers.map(layer => {
                                    if (layer.type !== Shape.CircleWithText) return layer

                                    return {
                                        ...layer,
                                        text: {
                                            ...layer.text,
                                            title: (activeStep + 2 + stepIndex)
                                                .toString()
                                                .padStart(2, '0') // update step numbering of all the steps after newly added
                                        }
                                    }
                                })
                            }
                        })
                    ]

                    return {
                        ...state,
                        steps: newSteps,
                        activeStep: Math.min(newIndex, steps.length),
                        selectedLayersIds: []
                    }
                case 'end':
                    const endingIndex =
                        steps.length - (steps[steps.length - 1].kind === 'outro' ? 1 : 0)

                    return {
                        ...state,
                        steps: [...steps.slice(0, endingIndex), step, ...steps.slice(endingIndex)],
                        activeStep: endingIndex,
                        selectedLayersIds: []
                    }
                case 'outro':
                    return {
                        ...state,
                        steps: [...steps, step],
                        activeStep: steps.length,
                        selectedLayersIds: []
                    }
                default:
                    return state
            }
        }

        case types.DUPLICATE_STEPS: {
            const { steps: duplications, pasteIdx, newActiveStep } = action.payload

            const generateLayers = (layers: Array<QuickGuiddeLayerType>, titleIdx: number) => {
                return layers.map(layer => {
                    if (layer.type !== Shape.CircleWithText) return { ...layer, id: uuid() }

                    return {
                        ...layer,
                        id: uuid(),
                        text: {
                            ...layer.text,
                            title: titleIdx.toString().padStart(2, '0'),
                            id: uuid()
                        }
                    }
                })
            }

            const duplicationSteps = duplications.map(({ noteId, ...newStep }, stepIndex) => ({
                // we don't need the noteId in the new step
                ...newStep,
                id: uuid(), // need to overwrite step's id to be unique
                layers: generateLayers(newStep.layers, pasteIdx + stepIndex + 1),
                isDuplicated: true
            }))

            const start = steps.slice(0, pasteIdx + 1)

            const newSteps = [
                ...start,
                ...duplicationSteps,
                ...steps.slice(pasteIdx + 1).map((step, stepIndex) => ({
                    ...step,
                    // update step numbering if the one exists
                    layers: generateLayers(
                        step.layers,
                        start.length + duplicationSteps.length + stepIndex
                    )
                }))
            ]

            return {
                ...state,
                steps: newSteps,
                activeStep: newActiveStep,
                selectedSteps: []
            }
        }

        case types.REMOVE_ACTIVE_STEP: {
            const deletedStepId = steps[activeStep].id

            // remove step and update step numbering if the one exists
            const newSteps = steps
                .filter((step, idx) => idx !== activeStep && !selectedSteps.includes(step.id))
                .map((step, stepIndex, filteredSteps) => {
                    // if step after deleted had transition, need to re-draw transition
                    if (
                        stepIndex === activeStep ||
                        stepIndex - 1 === activeStep ||
                        stepIndex + 1 === activeStep
                    )
                        step.isChanged = true
                    // in case some CTA is pointing to the deleted step, rest the cta link
                    if (step.cta) {
                        // In case a single CTA is pointing to the deleted step, rest the cta link
                        if (step.cta.ctaType === 'single') {
                            if ([deletedStepId, ...selectedSteps].includes(step.cta.action.link)) {
                                step.cta.action.link = ''
                                step.cta.action.enabled = false
                            }
                        } else {
                            // In case a multi CTA item is pointing to the deleted step, change the pointer to the next
                            // step if exists or to the first step
                            step.cta.actions = step.cta.actions.map(action => {
                                if ([deletedStepId, ...selectedSteps].includes(action.link)) {
                                    const nextStep =
                                        filteredSteps[stepIndex + 1] || filteredSteps[0]

                                    if (nextStep) action.link = nextStep.id
                                }
                                return action
                            })
                        }
                    }

                    return {
                        ...step,
                        layers: step.layers.map(layer => {
                            if (layer.type !== Shape.CircleWithText) return layer

                            // if no cover first index will be 00 so need to add 1
                            // if there's a cover, regular step will start at 01
                            const base = steps[0]?.kind === 'cover' ? 0 : 1
                            const index = (stepIndex + base).toString().padStart(2, '0')

                            return {
                                ...layer,
                                text: {
                                    ...layer.text,
                                    title: index // update step numbering of all the steps after newly added
                                }
                            }
                        })
                    }
                })

            return {
                ...state,
                activeStep: Math.min(activeStep, newSteps.length - 1),
                steps: newSteps,
                selectedSteps: []
            }
        }

        case types.SELECT_LAYER: {
            const { selection, isMultiSelect } = action.payload

            // If we have only 1 layer selected, and we click on it again, we should not do anything
            if (selectedLayersIds.length === 1 && selectedLayersIds[0] === selection) return state

            const calculateSelection = () => {
                const { layers } = steps[activeStep]
                const overlayLayer = layers.find(layer => layer.type === Shape.Overlay)

                const iSelectingOverlay = overlayLayer?.id === selection
                const isOverlaySelected = selectedLayersIds.includes(overlayLayer?.id || '')

                const isEmpty = selection === null
                if (isEmpty) return [] // Clicked outside (#workspace) or on background shape

                // Selected multiple layers at once - return them as is
                if (Array.isArray(selection)) return selection
                // Single select without shift or selecting Overlay or Overlay is already selected - return new selection
                if (!isMultiSelect || iSelectingOverlay || isOverlaySelected) return [selection]
                // Single select with shift pressed - toggle new layer (multiSelect property is true)
                if (!selectedLayersIds.includes(selection)) return [...selectedLayersIds, selection]
                else return selectedLayersIds.filter(id => id !== selection)
            }

            return {
                ...state,
                selectedLayersIds: calculateSelection(),
                selectedSteps: []
            }
        }

        case types.SET_MULTIPLE_QUICK_GUIDDE_AUDIO_NOTES: {
            const newSteps = steps.map(step => {
                const { tempAudioNote, ...stepWithoutTemp } = step

                const payloadStep = action.payload.find(payload => step.id === payload.stepId)
                if (!payloadStep) return step

                const {
                    audioNote: payloadAudioNote,
                    audioLayer: payloadAudioLayer,
                    subtitles,
                    subGenerationId
                } = payloadStep

                const defaultDuration = 3
                const videoLayer = step.layers.find(layer => layer.type === Shape.Video) as
                    | VideoShapeType
                    | undefined
                const videoDuration = (videoLayer?.end || 0) - (videoLayer?.start || 0) || 0

                const maxStepDuration = Math.ceil(
                    Math.max(payloadAudioNote.audioDuration, videoDuration, defaultDuration)
                )

                const currentAudioLayer = step.layers.find(
                    layer => layer.type === Shape.AudioCircle
                ) as AudioCircleType | undefined

                return {
                    ...stepWithoutTemp,
                    duration: maxStepDuration,
                    subtitles,
                    audioNote: payloadAudioNote,
                    layers: [
                        // if there's a new audio layer, remove the old one
                        ...step.layers.filter(layer =>
                            currentAudioLayer && payloadAudioLayer
                                ? layer.type !== Shape.AudioCircle
                                : true
                        ),
                        ...(payloadAudioLayer?.type === Shape.AudioCircle
                            ? // restore old audio layer but override its image (based on Speaker)
                              [
                                  {
                                      ...(currentAudioLayer || payloadAudioLayer),
                                      ...(payloadAudioLayer && { render: payloadAudioLayer.render })
                                  } as AudioCircleType
                              ]
                            : [])
                    ],
                    ...(subGenerationId && { subGenerationId })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.REMOVE_MULTIPLE_QUICK_GUIDDE_AUDIO_NOTES: {
            const newSteps = steps.map(step => {
                const shouldRemoveAudioNote = action.payload.has(step.id)

                if (!shouldRemoveAudioNote) return step

                const { tempAudioNote, audioNote, ...rest } = step

                return {
                    ...rest,
                    // If we remove audioNote, we should remove audioCircle layer as well
                    layers: rest.layers.filter(layer => layer.type !== Shape.AudioCircle)
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_AUDIO_NOTE: {
            const newSteps = steps.map((step, stepIndex) => {
                const {
                    audioNote: payloadAudioNote,
                    stepId,
                    subtitles: payloadSubtitles,
                    subGenerationId
                } = action.payload || {}

                if ((!stepId && stepIndex !== activeStep) || (stepId && step.id !== stepId)) {
                    return step
                }

                const { tempAudioNote, audioNote, ...rest } = step

                const defaultDuration = 3
                const audioDuration = payloadAudioNote?.audioDuration || 0
                const videoLayer = step.layers.find(layer => layer.type === Shape.Video) as
                    | VideoShapeType
                    | undefined
                const videoDuration = (videoLayer?.end || 0) - (videoLayer?.start || 0) || 0
                const subtitlesDuration = step.subtitles?.[step.subtitles.length - 1]?.end || 0

                const maxStepDuration = Math.ceil(
                    Math.max(audioDuration, subtitlesDuration, videoDuration, defaultDuration)
                )

                return {
                    ...rest,
                    duration: maxStepDuration,
                    // If we remove audioNote, we should remove audioCircle layer as well
                    layers: rest.layers.filter(layer =>
                        payloadAudioNote ? true : layer.type !== Shape.AudioCircle
                    ),
                    ...(payloadSubtitles && { subtitles: payloadSubtitles }),
                    ...(payloadAudioNote && { audioNote: payloadAudioNote }),
                    ...(subGenerationId && { subGenerationId })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_AUDIO_NOTE_TEMP_MARKDOWN: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const { audioNote, ...rest } = step
                if (!audioNote) return step
                const { tempMarkdown, ...audioNoteWithoutTemp } = audioNote

                return {
                    ...rest,
                    audioNote: {
                        ...audioNoteWithoutTemp,
                        ...(action.payload ? { tempMarkdown: action.payload } : {})
                    }
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_TEMP_QUICK_GUIDDE_AUDIO_NOTE: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step
                const { audioNote, tempAudioNote, ...rest } = step

                return {
                    ...rest,
                    ...(action.payload ? { tempAudioNote: action.payload } : {})
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_STEP_TITLE: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step
                return {
                    ...step,
                    title: action.payload
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_QUICK_GUIDDE_VIDEO_STEP_RANGES: {
            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const { audioNote } = step || {}
                const { start, end } = action.payload

                const audioDuration =
                    audioNote && 'audioDuration' in audioNote ? audioNote.audioDuration : 0
                const videoDuration = end - start

                const maxStepDuration = Math.ceil(Math.max(audioDuration, videoDuration))

                return {
                    ...step,
                    duration: maxStepDuration,
                    layers: step.layers.map(layer => {
                        if (layer.type !== Shape.Video) return layer
                        return {
                            ...layer,
                            start,
                            end,
                            generateArtifacts: true as const
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.MULTI_ALIGN_SELECTED_LAYERS: {
            const selectedLayers = steps[activeStep].layers.filter(layer =>
                selectedLayersIds.includes(layer.id)
            )

            const getLayerSides = (layer: QuickGuiddeLayerType) => {
                const width = 'width' in layer ? layer.width || 0 : 0
                const height = 'height' in layer ? layer.height || 0 : 0
                const radius = 'radius' in layer ? layer.radius : 0
                const stepNumberingSize = 'circle' in layer ? layer.circle.radius * 2 : 0
                return {
                    width: width || stepNumberingSize,
                    height: height || stepNumberingSize,
                    radius
                }
            }

            const getLayerCoordinate = (axis: 'x' | 'y', edge: 'start' | 'end') => {
                return selectedLayers.map(layer => {
                    const { width, height, radius } = getLayerSides(layer)
                    const size = radius ? radius : axis === 'x' ? width : height
                    const scale = axis === 'x' ? layer.scaleX : layer.scaleY

                    let delta = 0
                    if (edge === 'start') delta = radius ? -(radius * scale) : 0
                    else delta = size * scale

                    return layer[axis] + delta
                })
            }

            const newSteps = steps.map((step, stepIndex) => {
                if (stepIndex !== activeStep) return step

                const startX = Math.min(...getLayerCoordinate('x', 'start'))
                const endX = Math.max(...getLayerCoordinate('x', 'end'))
                const startY = Math.min(...getLayerCoordinate('y', 'start'))
                const endY = Math.max(...getLayerCoordinate('y', 'end'))

                return {
                    ...step,
                    layers: step.layers.map(layer => {
                        if (!selectedLayersIds.includes(layer.id)) return layer
                        const { width, height, radius } = getLayerSides(layer)

                        switch (action.payload) {
                            case 'left':
                                return {
                                    ...layer,
                                    x: startX + radius * layer.scaleX
                                }
                            case 'right':
                                return {
                                    ...layer,
                                    x: endX - (radius || width) * layer.scaleX
                                }
                            case 'top':
                                return {
                                    ...layer,
                                    y: startY + radius * layer.scaleY
                                }
                            case 'bottom':
                                return {
                                    ...layer,
                                    y: endY - (radius || height) * layer.scaleY
                                }
                            case 'verticalCenter':
                                return {
                                    ...layer,
                                    y: (startY + endY) / 2 - (height * layer.scaleY) / 2
                                }
                            case 'horizontalCenter':
                                return {
                                    ...layer,
                                    x: (startX + endX) / 2 - (width * layer.scaleX) / 2
                                }
                            default:
                                return layer
                        }
                    })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.APPLY_LAYER_TO_ALL_STEPS: {
            if (!selectedLayersIds.length) return state

            let layerObject: QuickGuiddeLayerType | null = null
            const stepIndex = steps.findIndex(step =>
                step.layers.find(layer => {
                    if (layer.id === action.payload) {
                        layerObject = layer
                        return true
                    }
                })
            )

            if (!layerObject) return state

            const newSteps = steps.map((step, index) => {
                const isCurrentStep = index === stepIndex
                const isRegularStep = step.kind === 'step'
                const isVideoStep = step.layers.find(layer => layer.type === Shape.Video)

                if (isCurrentStep || !isRegularStep || isVideoStep) return step

                return {
                    ...step,
                    layers: [...step.layers, layerObject as QuickGuiddeLayerType]
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_STEPS_SUBTITLES: {
            const newSteps = steps.map(step => {
                const { subtitles: stepSubtitles, ...stepWithoutSubtitles } = step

                const payloadStep = action.payload.find(payload => step.id === payload.stepId)
                if (!payloadStep) return step

                const {
                    subtitles,
                    subGenerationId,
                    audioNote,
                    applyToSubtitles,
                    applyToDescription
                } = payloadStep

                const isSpeechToText = step.audioNote?.type === 'speechToText'
                const shouldSaveDescription = isSpeechToText && applyToDescription

                return {
                    ...stepWithoutSubtitles,
                    ...(subGenerationId && { subGenerationId }),
                    ...(applyToSubtitles && { subtitles }),
                    ...(isSpeechToText && {
                        audioNote: {
                            ...(step.audioNote as SpeechToTextType),
                            text: audioNote.text,
                            markdown: audioNote.markdown
                        }
                    }),
                    ...(shouldSaveDescription && { description: audioNote.text })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.SET_STEP_SUBTITLES_BLOCK: {
            const { stepIndex, blockIndex, subtitlesBlock, operation } = action.payload

            const { duration: stepDuration } = steps[stepIndex]

            const CHARS_LIMIT = 140

            const errors = {
                timeErrors: {
                    startTime: 'Caption start time should be before end time',
                    overlap: "Caption blocks can't overlap",
                    startBeforeStep: "Caption start can't be before the previous step's end",
                    stepDuration: "Caption block can't exceed the step's duration"
                },
                textErrors: {
                    length: `Caption text can't exceed ${CHARS_LIMIT} characters`
                }
            }

            const validateCaptionBlock = (
                stepCaptions: Exclude<StepType['subtitles'], undefined>,
                captionBlock: SubtitleType,
                captionIndex: number
            ) => {
                const { start, end, text } = captionBlock
                const newCaption: SubtitleType = { start, end, text }

                const isOverCharsLimit = text.length > CHARS_LIMIT
                if (isOverCharsLimit) newCaption.textError = errors.textErrors.length

                const isStartBiggerThanEnd = start > end
                if (isStartBiggerThanEnd) newCaption.timeError = errors.timeErrors.startTime

                const isOutsideStepDuration = end > stepDuration
                if (isOutsideStepDuration) newCaption.timeError = errors.timeErrors.stepDuration

                const isBeforeStepStart = start < 0
                if (isBeforeStepStart) newCaption.timeError = errors.timeErrors.startBeforeStep

                const hasOverlapInsideStep = stepCaptions?.some((block, idx) => {
                    const isOverlapsBefore = idx < captionIndex && block.end > start
                    const isOverlapsAfter = idx > captionIndex && block.start < end

                    return isOverlapsBefore || isOverlapsAfter
                })
                if (hasOverlapInsideStep) newCaption.timeError = errors.timeErrors.overlap

                return newCaption
            }

            const generateNewSubtitles = (stepSubtitles: SubtitlesType): StepType['subtitles'] => {
                switch (operation) {
                    case 'add':
                        if (!subtitlesBlock) return stepSubtitles
                        return [
                            ...stepSubtitles.slice(0, blockIndex),
                            subtitlesBlock,
                            ...stepSubtitles.slice(blockIndex)
                        ]
                    case 'update':
                        if (!subtitlesBlock) return stepSubtitles
                        return stepSubtitles.map((elem, index) => {
                            const isActive = index === blockIndex
                            return isActive ? subtitlesBlock : elem
                        })
                    case 'delete':
                        if (stepSubtitles.length === 1) return undefined
                        return stepSubtitles.filter((_, index) => index !== blockIndex)

                    default:
                        return stepSubtitles
                }
            }

            const newSteps = steps.map((step, idx) => {
                if (idx !== stepIndex) return step

                const newStepSubtitles = generateNewSubtitles(step.subtitles || [])

                const validatedSubtitles = newStepSubtitles?.map((block, index) => {
                    return validateCaptionBlock(newStepSubtitles, block, index)
                })

                const { subtitles, ...stepWithoutSubtitles } = step

                return {
                    ...stepWithoutSubtitles,
                    ...(validatedSubtitles && { subtitles: validatedSubtitles })
                }
            })

            return {
                ...state,
                steps: newSteps
            }
        }

        case types.ENABLE_QG_SHORTCUTS: {
            return {
                ...state,
                isEditorBlockingShortcuts: false
            }
        }

        case types.DISABLE_QG_SHORTCUTS: {
            return {
                ...state,
                isEditorBlockingShortcuts: true
            }
        }

        case types.RESET_STEPS:
            return initialState

        default: {
            return state
        }
    }
}
