export const firebaseConfig = {
    "projectId": "guidde-dev5",
    "appId": "1:22601297927:web:17b24cf2a59c9ddb13baa2",
    "databaseURL": "https://guidde-dev5-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev5.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyAErQ-ucqLESyGrxVJv7qP2yyEjszJvW8U",
    "authDomain": "dev5.guidde.com",
    "messagingSenderId": "22601297927",
    "environment": "guidde-dev5"
}

export const stripeKey =
'pk_test_51P1nQvIFBXXSFf8ueJQQjxrelrmZJtvgdQeY8JU0y9sFrdmfHvYZKutujbymViRQXJeNpl0rDbL6volhSboIcVxC00dUQDu2uA'
export const stiggClientKey =
'client-96e8467f-d352-4928-8c2d-a994a9ecf718:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'client-96e8467f-d352-4928-8c2d-a994a9ecf718:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'