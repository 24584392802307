import React, {
    Fragment,
    memo,
    useEffect,
    useMemo,
    useState,
    type KeyboardEventHandler,
    useCallback
} from 'react'

import { ChromePicker } from 'react-color'

import {
    ToggleButton,
    ToggleButtonGroup,
    Popover,
    Divider,
    type ToggleButtonProps,
    type TextFieldProps
} from '@mui/material'
import {
    Select,
    Icon,
    IconButton,
    Input,
    Tooltip,
    ShadowBox,
    Button,
    DropdownMenu,
    spacings
} from '@guidde/design-system'

import {
    faBold,
    faUnderline,
    faParagraphRtl,
    faItalic,
    faLineHeight,
    faStrikethrough,
    faHighlighter,
    faCheck,
    faAlignLeft,
    faAlignRight,
    faAlignCenter,
    faIndent,
    faOutdent,
    faText,
    faA,
    faListUl,
    faListOl,
    faLink
} from '@fortawesome/pro-solid-svg-icons'

import { faTrashCan as faTrash } from '@fortawesome/pro-regular-svg-icons'
import { type Level } from '@tiptap/extension-heading'
import { lineHeights, type Direction } from './custom/extensions'
import {
    type ActionButtonProps,
    type BasicDropdownActionProps,
    type BasicPopoverToggleGroupProps,
    type BasicSelectionActionProps,
    type BasicSetActionWithInputProps,
    type TSelectOption
} from './types'

const BasicDropdownAction = ({ label, action, options, icon }: BasicDropdownActionProps) => {
    return (
        <DropdownMenu onMenuItemSelect={action} options={options}>
            <Tooltip title={label}>
                <IconButton variant="text" color="secondary" label={label}>
                    <Icon icon={icon} size="1x" />
                </IconButton>
            </Tooltip>
        </DropdownMenu>
    )
}

const BasicSelectionAction = ({ action, options, value }: BasicSelectionActionProps) => {
    return (
        <Select
            onChange={(_, newVal) => action(newVal as TSelectOption)}
            options={options}
            value={value}
        />
    )
}

const BasicSetActionWithInput = ({
    onApply,
    onDelete,
    isActive,
    icon,
    value,
    label,
    placeholder,
    tooltip
}: BasicSetActionWithInputProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [inputValue, setInputValue] = useState(value || '')

    useEffect(() => {
        setInputValue(value ?? '')
    }, [value])

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleDelete = () => {
        onDelete?.()
        handleClosePopover()
    }

    const handleSave = () => {
        if (!inputValue) return handleDelete()
        onApply(inputValue)
        handleClosePopover()
    }

    const handleKeypress: KeyboardEventHandler<HTMLDivElement> = e => {
        if (e.key === 'Enter') {
            e.stopPropagation()
            handleSave()
        }
    }

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleChange: TextFieldProps['onChange'] = e => setInputValue(e.target.value)

    return (
        <>
            <Divider flexItem orientation="vertical" />
            <Tooltip title={tooltip}>
                <IconButton
                    onClick={handleOpenPopover}
                    variant="text"
                    label={label}
                    color={isActive ? 'primary' : 'secondary'}
                    size="small"
                >
                    <Icon icon={icon} size="1x" />
                </IconButton>
            </Tooltip>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onKeyUp={handleKeypress}
            >
                <ShadowBox
                    shadowSize="sm"
                    sx={{
                        borderRadius: spacings.md,
                        display: 'flex',
                        p: spacings.sm,
                        minWidth: 350
                    }}
                >
                    <Input
                        inputProps={{ autoFocus: true }}
                        value={inputValue}
                        onChange={handleChange}
                        placeholder={placeholder}
                    />
                    <Button
                        onClick={handleSave}
                        color="secondary"
                        variant="text"
                        label="save"
                        size="small"
                    >
                        Apply
                    </Button>
                    {isActive && onDelete && (
                        <IconButton
                            onClick={handleDelete}
                            color="secondary"
                            variant="text"
                            label="delete"
                            size="small"
                        >
                            <Icon icon={faTrash} size="sm" />
                        </IconButton>
                    )}
                </ShadowBox>
            </Popover>
        </>
    )
}

export const BasicColorPopoverGroup = ({
    editor,
    colorType
}: ActionButtonProps & { colorType: 'text' | 'background' }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectedColor, setSelectedColor] = useState<string>(
        colorType === 'text' ? '#000000' : 'transparent'
    )

    const handleSelectionUpdate = useCallback(() => {
        let colorAttr
        if (colorType === 'text') {
            const { color } = editor.getAttributes('textStyle')
            colorAttr = color || '#000000'
        } else if (colorType === 'background') {
            const { color } = editor.getAttributes('highlight')
            colorAttr = color || 'transparent'
        }
        setSelectedColor(colorAttr)
    }, [editor, colorType])

    useEffect(() => {
        if (!editor) return

        handleSelectionUpdate()

        editor.on('selectionUpdate', handleSelectionUpdate)

        return () => {
            editor.off('selectionUpdate', handleSelectionUpdate)
        }
    }, [editor, handleSelectionUpdate])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleColorChange = (color: string) => {
        setSelectedColor(color)
        if (colorType === 'text') {
            editor.chain().focus().setColor(color).run()
        } else if (colorType === 'background') {
            editor.chain().focus().setHighlight({ color }).run()
        }
    }

    const open = Boolean(anchorEl)
    const id = open
        ? colorType === 'text'
            ? 'color-popover'
            : 'background-color-popover'
        : undefined

    const tooltipTitle = colorType === 'text' ? 'Text Color' : 'Text Background Color'
    const icon = colorType === 'text' ? faA : faHighlighter
    const label = colorType === 'text' ? 'color' : 'background color'

    return (
        <>
            <Divider flexItem orientation="vertical" />
            <Tooltip title={tooltipTitle}>
                <ShadowBox
                    sx={{
                        position: 'relative',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 2,
                            left: 2,
                            right: 2,
                            height: 6,
                            backgroundColor: selectedColor,
                            borderRadius: '2px'
                        }
                    }}
                    shadowSize={0}
                >
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        variant="text"
                        label={label}
                        color="secondary"
                    >
                        <Icon icon={icon} size="1x" />
                    </IconButton>
                </ShadowBox>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <ChromePicker
                    disableAlpha
                    color={selectedColor}
                    onChange={color => handleColorChange(color.hex)}
                />
            </Popover>
        </>
    )
}

export const BasicPopoverToggleGroup = memo(
    ({ options, value, onChange, icon, label, tooltip }: BasicPopoverToggleGroupProps) => {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleClose = () => {
            setAnchorEl(null)
        }

        const open = Boolean(anchorEl)
        const id = open ? 'popover-select' : undefined

        const handleOnChange: ToggleButtonProps['onChange'] = (_, newValue) => {
            onChange(newValue)
            handleClose()
        }

        return (
            <Fragment>
                <Divider flexItem orientation="vertical" />
                <Tooltip title={tooltip}>
                    <IconButton
                        onClick={handleClick}
                        label={label}
                        variant="text"
                        color="secondary"
                        size="small"
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <ToggleButtonGroup value={value} exclusive onChange={handleOnChange}>
                        {options.map(option => (
                            <ToggleButton key={option.value} value={option.value} color="primary">
                                {option.icon}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Popover>
            </Fragment>
        )
    }
)

export const FontFamilyDropdown = ({ editor }: ActionButtonProps) => {
    const fontFamilies = useMemo(
        () => ['Inter', 'Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Georgia'],
        []
    )

    const options = useMemo(
        () =>
            fontFamilies.map(fontFamily => ({
                label: fontFamily,
                value: fontFamily
            })),
        [fontFamilies]
    )

    return BasicSelectionAction({
        action: item => {
            editor.chain().focus().setFontFamily(item.value).run()
        },
        options,
        value: {
            label: editor.getAttributes('textStyle').fontFamily || 'Inter',
            value: editor.getAttributes('textStyle').fontFamily || 'Inter'
        }
    })
}

export const HeadingDropdown = ({ editor }: ActionButtonProps) => {
    const headings = useMemo(() => ['paragraph', 1, 2, 3, 4, 5, 6], [])

    return BasicSelectionAction({
        action: item => {
            editor.chain().focus()
            if (item.value === 'paragraph') {
                editor.chain().focus().setParagraph().run()
            } else {
                editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: Number(item.value) as Level })
                    .run()
            }
        },
        options: headings.map(level => ({
            label: level === 'paragraph' ? 'Paragraph' : `Heading ${level}`,
            value: String(level)
        })),
        value: editor.isActive('heading')
            ? {
                  label: `Heading ${editor.getAttributes('heading').level || 1}`,
                  value: String(editor.getAttributes('heading').level || 1)
              }
            : {
                  label: 'Paragraph',
                  value: 'paragraph'
              }
    })
}

export const LineHeightDropdown = ({ editor }: ActionButtonProps) => {
    return BasicDropdownAction({
        action: item => {
            editor.chain().focus().setLineHeight(item.value).run()
        },
        options: lineHeights.map(lineHeight => {
            const isSelected = lineHeight.value === editor.getAttributes('textStyle').lineHeight

            return {
                label: lineHeight.label,
                value: lineHeight.value,
                isSelected,
                icon: isSelected ? faCheck : undefined,
                iconColor: isSelected ? 'primary' : undefined
            }
        }),
        label: 'Line height',
        icon: faLineHeight
    })
}

export const SetLink = ({ editor }: ActionButtonProps) =>
    BasicSetActionWithInput({
        onApply: url => {
            const { from, to } = editor.state.selection
            const selectedText = editor.state.doc.textBetween(from, to, ' ')
            if (selectedText.trim()) editor.chain().focus().setLink({ href: url }).run()
            else
                editor
                    .chain()
                    .focus()
                    .insertContent({
                        type: 'text',
                        text: url,
                        marks: [{ type: 'link', attrs: { href: url } }]
                    })
                    .run()
        },
        onDelete: () => {
            editor.chain().focus().unsetLink().run()
        },
        isActive: editor.isActive('link'),
        value: editor.getAttributes('link').href,
        icon: faLink,
        label: 'Set link',
        tooltip: 'Link',
        placeholder: 'https://...'
    })

export const TextAlignmentPopoverGroup = ({ editor }: ActionButtonProps) => {
    const activeAlignment = editor.isActive({ textAlign: 'left' })
        ? 'left'
        : editor.isActive({ textAlign: 'center' })
          ? 'center'
          : editor.isActive({ textAlign: 'right' })
            ? 'right'
            : 'left'

    const options = [
        { value: 'left', icon: <Icon icon={faAlignLeft} size="1x" /> },
        { value: 'center', icon: <Icon icon={faAlignCenter} size="1x" /> },
        { value: 'right', icon: <Icon icon={faAlignRight} size="1x" /> }
    ]

    const ActiveIcon = options.find(option => option.value === activeAlignment)?.icon

    const handleChange = (newAlignment: string) =>
        editor.chain().focus().setTextAlign(newAlignment).run()

    return (
        <BasicPopoverToggleGroup
            options={options}
            value={activeAlignment}
            onChange={handleChange}
            icon={ActiveIcon}
            label="Alignment"
            tooltip="Text alignment"
        />
    )
}

export const ListPopoverGroup = ({ editor }: ActionButtonProps) => {
    const activeList = editor.isActive('bulletList')
        ? 'bullet'
        : editor.isActive('orderedList')
          ? 'ordered'
          : 'none'

    const options = [
        { value: 'bullet', icon: <Icon icon={faListUl} size="1x" /> },
        { value: 'ordered', icon: <Icon icon={faListOl} size="1x" /> },
        { value: 'indent', icon: <Icon icon={faIndent} size="1x" /> },
        { value: 'outdent', icon: <Icon icon={faOutdent} size="1x" /> }
    ]

    const ActiveIcon = options.find(option => option.value === activeList)?.icon || options[0].icon

    const handleChange = (newListType: string) => {
        switch (newListType) {
            case 'bullet':
                editor.chain().focus().toggleBulletList().run()
                break
            case 'ordered':
                editor.chain().focus().toggleOrderedList().run()
                break
            case 'indent':
                editor.chain().focus().sinkListItem('listItem').run()
                break
            case 'outdent':
                editor.chain().focus().liftListItem('listItem').run()
                break
            default:
                editor.chain().focus().liftListItem('listItem').run()
        }
    }

    return (
        <BasicPopoverToggleGroup
            options={options}
            value={activeList}
            onChange={handleChange}
            icon={ActiveIcon}
            label="List Type"
            tooltip="List type"
        />
    )
}

export const TextStylePopoverGroup = ({ editor }: ActionButtonProps) => {
    const options = [
        { value: 'bold', icon: <Icon icon={faBold} size="1x" /> },
        { value: 'italic', icon: <Icon icon={faItalic} size="1x" /> },
        { value: 'underline', icon: <Icon icon={faUnderline} size="1x" /> },
        { value: 'strike', icon: <Icon icon={faStrikethrough} size="1x" /> }
    ]

    const activeStyles = [['bold', 'italic', 'underline', 'strike']].flatMap(styles =>
        styles.filter(style => editor.isActive(style))
    )

    const handleChange = (value: string) => {
        switch (value) {
            case 'bold':
                editor.chain().focus().toggleBold().run()
                break
            case 'italic':
                editor.chain().focus().toggleItalic().run()
                break
            case 'underline':
                editor.chain().focus().toggleUnderline().run()
                break
            case 'strike':
                editor.chain().focus().toggleStrike().run()
                break
        }
    }

    return (
        <BasicPopoverToggleGroup
            options={options}
            value={activeStyles}
            onChange={handleChange}
            icon={<Icon icon={faText} size="1x" />}
            label="Text Style"
            tooltip="Text style"
        />
    )
}

export const TextDirectionPopoverGroup: React.FC<ActionButtonProps> = ({ editor }) => {
    const activeDirection = editor.isActive({ dir: 'rtl' }) ? 'rtl' : 'ltr'

    const options = [
        { value: 'ltr', icon: <Icon icon={faParagraphRtl} size="1x" flip="horizontal" /> },
        { value: 'rtl', icon: <Icon icon={faParagraphRtl} size="1x" /> }
    ]

    const ActiveIcon =
        options.find(option => option.value === activeDirection)?.icon || options[0].icon

    const handleChange = (newDirection: string) => {
        if (newDirection !== 'ltr' && newDirection !== 'rtl' && newDirection !== 'auto') {
            throw new Error('Invalid direction. Must be "ltr" or "rtl".')
        }

        if (newDirection === 'auto') editor.chain().focus().unsetTextDirection().run()
        else
            editor
                .chain()
                .focus()
                .setTextDirection(newDirection as Direction)
                .run()
    }

    return (
        <BasicPopoverToggleGroup
            options={options}
            value={activeDirection}
            onChange={handleChange}
            icon={ActiveIcon}
            label="Text Direction"
            tooltip="Text Direction"
        />
    )
}

export const TextColorPopoverGroup = ({ editor }: ActionButtonProps) =>
    BasicColorPopoverGroup({ editor, colorType: 'text' })

export const TextBackgroundColorPopoverGroup = ({ editor }: ActionButtonProps) =>
    BasicColorPopoverGroup({ editor, colorType: 'background' })
