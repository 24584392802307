import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RichTextEditor, type RichTextEditorProps } from 'UI/Components/RichTextEditor'
import { Box } from '@mui/material'
import { useBoolean, useNotification } from 'hooks'
import { SmartSkeleton } from './SmartSkeleton'
import RawHTMLComponent from 'UI/Components/RichTextEditor/RawHTMLComponent'

type Props = {
    isEditMode: boolean
    note?: string
    onUpdate: (_value: string) => Promise<void>
}

export const StepDescription = memo(({ isEditMode, note = '', onUpdate }: Props) => {
    const { showErrorNotification } = useNotification()
    const [tmp, setTmp] = useState<string | null>(null)
    const maxLength = useSelector(state => state.configs.audioConfigs.t2s.maxChars)
    const loading = useBoolean()

    useEffect(() => {
        if (isEditMode) return
        if (tmp === null) return

        loading.setTrue()
        onUpdate(tmp)
            .then(() => setTmp(null))
            .catch(() => showErrorNotification('Could not update the description'))
            .finally(loading.setFalse)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode])

    const handleChange: RichTextEditorProps['onUpdate'] = args => {
        setTmp(args.editor.getHTML())
    }

    if (loading.isTrue)
        return (
            <Box my={3}>
                <SmartSkeleton html={tmp || note} />
            </Box>
        )

    if (isEditMode) {
        return (
            <RichTextEditor
                content={note}
                onUpdate={handleChange}
                maxLength={maxLength}
                label="Description"
            />
        )
    }

    if (!note) return null

    return (
        <Box my={1} maxWidth="100%" position="relative" overflow="hidden">
            <RawHTMLComponent html={note} />
        </Box>
    )
})
