import { type FC, type PropsWithChildren } from 'react'
import { EditorContent, type EditorContentProps, type UseEditorOptions } from '@tiptap/react'

import { ShadowBox, ThemeProvider, Typography, spacings } from '@guidde/design-system'
import { Box } from '@mui/material'

import { useRichTextEditor } from './use-rich-text-editor'

import './styles.css'

const RichTextEditorControl: FC<PropsWithChildren & { label?: string }> = ({ label, children }) => (
    <Box display="flex" flexDirection="column" gap={spacings.md} mb={spacings.xl}>
        {label && (
            <Typography variant="text" color="grey.700" fontWeight="medium" size="sm">
                {label}
            </Typography>
        )}

        {children}
    </Box>
)
const RichTextEditorContainer: FC<PropsWithChildren> = ({ children }) => (
    <ShadowBox
        shadowSize={0}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacings.md,
            border: '1px solid',
            borderRadius: spacings.xl,
            borderColor: 'grey.300',
            m: 0,
            p: spacings['2xl']
        }}
    >
        {children}
    </ShadowBox>
)

const RichTextEditorActionsContianer: FC<PropsWithChildren> = ({ children }) => (
    <ShadowBox
        shadowSize={0}
        sx={{
            display: 'flex',
            gap: spacings.md,
            m: 0,
            p: 0
        }}
    >
        {children}
    </ShadowBox>
)

export const RichTextEditor: FC<RichTextEditorProps> = ({
    content,
    onUpdate,
    onBlur,
    maxLength,
    readOnly,
    label
}) => {
    const { editor, actionComponents } = useRichTextEditor({
        content,
        onUpdate,
        onBlur
    })

    if (!editor) return

    if (readOnly) return <EditorContent editor={editor} readOnly />

    return (
        <ThemeProvider>
            <RichTextEditorControl label={label}>
                <RichTextEditorActionsContianer>
                    {actionComponents.map((Component, idx) => (
                        <Component key={idx} editor={editor} />
                    ))}
                </RichTextEditorActionsContianer>
                <RichTextEditorContainer>
                    <EditorContent editor={editor} maxLength={maxLength} />
                </RichTextEditorContainer>
            </RichTextEditorControl>
        </ThemeProvider>
    )
}

export type RichTextEditorProps = {
    content?: string | null
    onUpdate?: UseEditorOptions['onUpdate']
    onBlur?: UseEditorOptions['onBlur']
    maxLength?: EditorContentProps['maxLength']
    readOnly?: EditorContentProps['readOnly']
    label?: string
}
