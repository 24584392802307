import { useRef, useState } from 'react'

import { Box, Dialog, IconButton, Skeleton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { SpacedGroup, VisibilitySensor } from 'UI/Components'

import { useBoolean } from 'hooks'

type Props = {
    imageUrl: string
    title: string
    stepLabel: JSX.Element
    isRtl: boolean
    minHeight?: number
    borderRadius?: number
    width?: string
}

export const ImageWithLoad = ({
    imageUrl,
    title,
    stepLabel,
    isRtl,
    minHeight = 350,
    width = '100%'
}: Props) => {
    const loaded = useBoolean()

    const dialog = useBoolean()

    const ref = useRef<HTMLImageElement>(null)

    const [blobUrl, setBlobUrl] = useState('')

    const handleError = () => {
        loaded.setTrue()
        if (ref.current) {
            ref.current.style.display = 'none'
        }
    }

    return (
        <>
            <Dialog open={dialog.isTrue} onClose={dialog.setFalse} maxWidth="lg" fullWidth>
                <SpacedGroup flexDirection="column" overflow="hidden" spacing={0} id="myDialog">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={`${isRtl ? 'row-reverse' : 'row'}`}
                        px={2}
                        py={1}
                        borderBottom={'1px solid #c5c5c5'}
                    >
                        <Box display="flex" flexDirection={`${isRtl ? 'row-reverse' : 'row'}`}>
                            <Box
                                position="relative"
                                width="auto !important"
                                style={{ marginRight: isRtl ? 0 : '8px' }}
                            >
                                <bdi>{stepLabel}</bdi>
                            </Box>

                            <Box fontSize={20} fontWeight={500}>
                                <bdi>{title}</bdi>
                            </Box>
                        </Box>
                        <SpacedGroup>
                            <IconButton onClick={dialog.setFalse} size="large">
                                <CloseIcon />
                            </IconButton>
                        </SpacedGroup>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <img
                            src={blobUrl}
                            style={{
                                height: 'auto',
                                overflow: 'hidden',
                                width: '100%'
                            }}
                        />
                    </Box>
                </SpacedGroup>
            </Dialog>

            <VisibilitySensor placeholder={<Box minHeight={minHeight} />} threshold={0.1} delay={0}>
                <>
                    {loaded.isFalse && (
                        <Box
                            width="100%"
                            height="100%"
                            minHeight={minHeight}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Skeleton
                                variant="rectangular"
                                style={{ height: 312, width, borderRadius: 12 }}
                            />
                        </Box>
                    )}
                    <img
                        ref={ref}
                        src={imageUrl}
                        width={width}
                        draggable={false}
                        onLoad={async () => {
                            loaded.setTrue()

                            const res = await fetch(imageUrl)
                            const blob = await res.blob()
                            const objectURL = URL.createObjectURL(blob)
                            setBlobUrl(objectURL)
                        }}
                        onError={handleError}
                        onClick={dialog.setTrue}
                        style={{
                            height: loaded.isTrue ? 'auto' : 0,
                            width: 'auto',
                            maxWidth: '100%',
                            borderRadius: 12,
                            objectFit: 'cover',
                            cursor: 'pointer'
                        }}
                        loading="lazy"
                        alt="preview"
                    />
                </>
            </VisibilitySensor>
        </>
    )
}
