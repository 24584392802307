import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { faLinkSimple as faCopy } from '@fortawesome/pro-solid-svg-icons'
import { Input, Typography, Tooltip, Icon } from '@guidde/design-system'

import { useNotification } from 'hooks'
import { copyToClipboard } from 'modules'

const maxLength = 600
type Props = {
    isEditMode: boolean
    title: string
    stepId: string
    showCopy: boolean
    onUpdate: (_value: string) => void
}
export const StepTitle = ({ showCopy, isEditMode, title, stepId, onUpdate }: Props) => {
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const [titleField, setTitleField] = useState(title)

    const copyStepUrl = (e: any) => {
        e.stopPropagation()

        copyToClipboard(`${window.location.href}#${stepId}`).then(() => {
            showSuccessNotification('Step URL copied')
        })
    }

    useEffect(() => {
        setTitleField(title)
    }, [title])

    if (isEditMode) {
        return (
            <Box width="100%">
                <Input
                    label="Step title"
                    value={titleField}
                    inputProps={{
                        maxLength
                    }}
                    onBlur={() => {
                        if (!titleField.length) {
                            showErrorNotification('Title cannot be empty')
                            setTitleField(title)
                            return
                        }
                        // we need to make update only if field was changed
                        if (title !== titleField) onUpdate(titleField)
                    }}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        setTitleField(e.target.value)
                    }
                />
                <Typography variant="text" size="sm" color="grey.400">
                    {`${titleField?.length || 0}/${maxLength}`}
                </Typography>
            </Box>
        )
    }

    return (
        <Box position="relative">
            <Typography fontWeight="bold" size="xl" variant="text">
                <bdi>{title}</bdi>
            </Typography>
            {showCopy && (
                <Box
                    position="absolute"
                    right={0}
                    top={0}
                    bottom={0}
                    marginY="auto"
                    sx={{ transform: 'translateX(30px)' }}
                >
                    <Tooltip title="Copy step URL" placement="top">
                        <Box onClick={copyStepUrl} color="secondary">
                            <Icon icon={faCopy} size="1x" />
                        </Box>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}
