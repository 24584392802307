import { useMemo } from 'react'

import { useEditor, type UseEditorOptions } from '@tiptap/react'
import {
    availableExtensions,
    componentMap,
    defaultGuiddeRichtTextEditorConfiguration,
    type GuiddeRichTextEditorConfiguration
} from './constants'

export const useRichTextEditor = (
    options: Omit<UseEditorOptions, 'extensions'>,
    configuration?: Partial<GuiddeRichTextEditorConfiguration>
) => {
    const actionComponents = useMemo(() => {
        const mergedConfiguration = Object.assign(
            defaultGuiddeRichtTextEditorConfiguration,
            configuration
        )

        return Object.entries(componentMap).flatMap(([key, Component]) =>
            mergedConfiguration[key as keyof GuiddeRichTextEditorConfiguration] ? [Component] : []
        )
    }, [configuration])

    const editor = useEditor({
        ...options,
        extensions: availableExtensions
    })

    return {
        editor,
        actionComponents
    }
}
